.qiymat-mavzu-quti video {
  width: 100%;
  height: 42vh;
  z-index: 5;
  border-radius: 0;
}
.buyurtma-tugma {
  display: block;
  width: 80%;
  font-family: "Roboto Mono", monospace;
  margin: 0.7vw auto;
  padding: 0.6vw 0;
  border: none;
  border-radius: 0.5vw;
  font-size: 1.1vw;
  background-color: rgb(141, 71, 21);
  color: white;
  cursor: pointer;
  transition: all 0.7s;
}

.buyurtma-tugma:hover {
  box-shadow: 0.25vw 0.25vw 1vw rgb(141, 71, 21);
  border-radius: 2vw;
}
.icon-buyurtma {
  vertical-align: middle;
}
