.foydalanuvchi-mantiq-icon {
  padding: 0.5vw;
  font-size: 1.5vw;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  column-gap: 0.4vw;
  justify-items: center;
  color: rgba(0, 0, 0, 0.4);
}
.foydalanuvchi-mantiq-icon > .sariq {
  color: rgb(141, 71, 21);
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.5s;
}

.foydalanuvchi-mantiq-icon > .sariq:hover {
  transform: scale(1.5);
}
