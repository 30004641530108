@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&family=Roboto+Mono:ital,wght@0,100;0,300;0,400;0,600;0,700;1,200;1,400;1,600;1,700&display=swap);
.menyu-quti {
  background-color: white;
  box-shadow: 4px 0px 15px rgba(20, 20, 20, 0.397);
  padding: 3px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(9, 1fr);
  height: 100vh;
}
.menyu-qiymat {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  transition: all 0.4s;
  text-align: center;
  margin: 0.1vw 0.1vh;
  text-decoration: none !important;
}
.icon-menyu {
  display: block;
  margin: 0 auto;
  color: rgb(141, 71, 21);
  font-size: 2.5vw;
  transition: all 0.4s;
}
.menyu-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: white; */
  text-decoration: none !important;
}
.menyu-logo img {
  cursor: pointer;
  width: 3vw;
  transition: all 0.5s;
  height: 6vh;
  transition: all 0.8s;
}
.menyu-logo > span {
  color: rgb(141, 71, 21);
  font-size: 1vw;
  text-transform: uppercase;
  font-weight: 900;
  transition: all 0.8;
}
.menyu-logo:hover img {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}
.menyu-logo:hover span {
  -webkit-transform: skew(5deg);
          transform: skew(5deg);
}

.menyu-qiymat > span {
  color: rgb(141, 71, 21);
  font-size: 1vw;
  font-weight: 700;
}

.menyu-qiymat > img {
  padding: 10px 0;
  margin: 0 auto;
  width: 40%;
}

.menyu-qiymat:hover {
  border-radius: 1vw;
  background-color: rgb(141, 71, 21);
}
.menyu-qiymat:hover span {
  color: white;
}
.menyu-qiymat:hover .icon-menyu {
  color: white;
}

.menyu-quti > .active {
  border-radius: 1vw;
  background-color: rgb(141, 71, 21);
}
.active > .icon-menyu,
.active > span {
  color: white !important;
}

.kard-quti {
  margin: 0.4vw;
  display: grid !important;
  padding: 0.7vh;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 2.5vw;
  -webkit-column-gap: 2.5vw;
          column-gap: 2.5vw;
  grid-row-gap: 0.9vh;
  row-gap: 0.9vh;
  height: 22.2vh;
}
.kard-icon {
  background-color: white;
  border-radius: 1vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  box-shadow: 0.2vw 0.3vw 0.5vw white;
  transition: all 0.8s;
  cursor: pointer;
  padding-bottom: 5px;
  overflow: hidden;
}
.kard-icon::before {
  content: "Tez kunda";
  font-size: 0.47vw;
  background-color: rgb(141, 71, 21);
  text-align: center;
  -webkit-transform: rotate(45deg) translate(2.8vw, -6vh);
          transform: rotate(45deg) translate(2.8vw, -6vh);
  color: white;
  padding: 0.2vh 0.3vw;
  width: 5vw;
  border-radius: 0.3vw;
  box-shadow: 0.2vw 0.05vw 0.5vw rgb(141, 71, 21);
}
.kard-icon > img {
  width: 7vw;
  height: 16vh;
  margin-bottom: 0.3vh;
}
.kard-icon > span {
  color: rgb(141, 71, 21);
  font-weight: 900;
  font-size: 0.8vw;
}

.kard-icon:hover {
  /* background-color: rgb(141, 71, 21); */
  box-shadow: 0.2vw 0.3vw 1vw rgb(141, 71, 21);
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.kardAktiv {
  box-shadow: 0.2vw 0.3vw 1vw rgb(141, 71, 21);
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.kard-icon:hover span {
  color: black;
}

.qidiruv-quti {
  margin: 0 0.7vw;
  display: grid;
  grid-template-columns: 40vw 1fr;
  align-items: center;
  height: 9.5vh;
}
.qidiruv-sarlavha > p {
  font-size: 1.5vw;
  font-family: "Roboto Mono", monospace;
  color: rgb(141, 71, 21);
}
#qidiruv-form > input {
  border: none;
  outline: none;
  font-size: 1.1vw;
  font-family: "Roboto Mono", monospace;
}
#qidiruv-form {
  border-radius: 0.35vw;
  box-shadow: 0.35vw 0.35vw 1vw #ccc;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 10%;
  padding: 0.35vw;
}
#qidiruv-form > button {
  border: none;
  background-color: white;
  cursor: pointer;
  font-size: 1vw;
}

.yonalish-quti {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2vw;
  -webkit-column-gap: 2vw;
          column-gap: 2vw;
  padding: 0 0.7vw;
}

.qiymat-quti-izoh {
  display: grid;
  grid-template-columns: 25% 1fr;
  justify-content: center;
  align-items: center;
}
.qiymat-quti-izoh {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  top: 0;
  padding-bottom: 0.4vh;
}
.qiymat-mavzu-scroll {
  background-color: white;
  padding: 0.4vw;
  border-radius: 0.4vw;
  box-shadow: 0.25vw 0.25vw 0.7vw rgb(102, 101, 101);
  font-family: "Roboto Mono" monospace;
}
.qiymat-quti-nomi h3 {
  margin: 0;
  padding: 0.25vh 0;
  font-size: 1.7vw;
}
.qiymat-quti-nomi p {
  padding: 0;
  font-size: 1vw;
  margin: 0.25vw 0;
}
.qiymat-quti-nomi span {
  font-weight: bold;
  display: block;
  margin-right: 1vw;
  font-size: 1.2vw;
  text-align: right;
}
.qiymat-quti-logo img {
  width: 7vw;
  height: 16vh;
}

.qiymat-mavzu-quti {
  background-color: white;
  overflow: auto;
  height: 52vh;
  position: relative;
}

.qiymat-quti-scroll {
  padding: 0.4vw;
  background-color: white;
  border-radius: 0.4vw;
  box-shadow: 0.25vw 0.25vw 0.7vw rgb(102, 101, 101);
  font-family: "Roboto Mono" monospace;
}
.qiymat-quti-mavzu ol {
  padding: 0 1vw 0 3.5vw;
}
.qiymat-quti-mavzu ol li {
  font-family: "Roboto Mono";
  transition: all 0.3s;
  font-size: 0.9vw;
  font-weight: 600;
  cursor: pointer;
}
.qiymat-quti-mavzu ol li:hover {
  text-decoration: underline;
}
::-webkit-scrollbar {
  width: 0.7vw;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to top, rgb(141, 71, 21), #ccc);
  border-radius: 0.7vw;
}

.qiymat-mavzu-quti video {
  width: 100%;
  height: 42vh;
  z-index: 5;
  border-radius: 0;
}
.buyurtma-tugma {
  display: block;
  width: 80%;
  font-family: "Roboto Mono", monospace;
  margin: 0.7vw auto;
  padding: 0.6vw 0;
  border: none;
  border-radius: 0.5vw;
  font-size: 1.1vw;
  background-color: rgb(141, 71, 21);
  color: white;
  cursor: pointer;
  transition: all 0.7s;
}

.buyurtma-tugma:hover {
  box-shadow: 0.25vw 0.25vw 1vw rgb(141, 71, 21);
  border-radius: 2vw;
}
.icon-buyurtma {
  vertical-align: middle;
}

.qiymat-sarlavha-quti {
  margin: 0 0.7vw;
  display: grid;
  grid-template-columns: 40vw 1fr;
  align-items: center;
  height: 7.5vh;
}
.qiymat-sarlavha-mavzu > p {
  line-height: 0px;
  margin: 0;
  font-size: 1.5vw;
  font-family: "Roboto Mono" monospace;
  color: rgb(141, 71, 21);
}

.qiymat-sarlavha-soni > p {
  line-height: 0px;
  margin: 0;
  font-size: 1.5vw;
  font-family: "Roboto Mono" monospace;
  color: rgb(141, 71, 21);
  text-align: right;
}

.foydalanuvchi-quti {
  background-color: white;
  box-shadow: 0.25vw 0px 1vw rgba(20, 20, 20, 0.397);
  padding: 0.2vw;
  height: 100vh;
  overflow: hidden;
}

.foydalanuvchi-malumot {
  padding-top: 0.35vh;
  display: grid;
  grid-template-columns: 25% 1fr 12%;
  align-items: center;
}
.foydalanuvchi-ism {
  display: flex;
  flex-direction: column;
  padding: 0.2vh 0.2vw;
  height: 100%;
  overflow: hidden;
}
.foydalanuvchi-rasm > img {
  width: 100%;
  border-radius: 2.1vw;
}
.foydalanuvchi-qongiroq {
  display: flex;
  justify-content: flex-start;
  margin-right: 1vw;
}
.foydalanuvchi-qongiroq > .icon-qongiroq {
  font-size: 2vw;
  cursor: pointer;
  /* text-align: center; */
  transition: all 0.3s;
}
.foydalanuvchi-qongiroq > .icon-qongiroq:hover {
  color: rgb(141, 71, 21);
  -webkit-animation: xabarchi 0.25s 3 ease-in alternate;
          animation: xabarchi 0.25s 3 ease-in alternate;
}
@-webkit-keyframes xabarchi {
  0% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }

  100% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
}
@keyframes xabarchi {
  0% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }

  100% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
}
.foydalanuvchi-daraja {
  font-weight: 900;
  font-size: 1.1vw;
  color: rgb(141, 71, 21);
}
.foydalanuvchi-nomi {
  font-size: 1vw;
}

.foydalanuvchi-daraja-quti > span {
  font-weight: 900;
  font-size: 1.1vw;
  color: rgb(141, 71, 21);
  padding: 0.5vh 0.5vw;
}
.foydalanuvchi-daraja-quti > span > a > .daraja-yuborish {
  color: rgb(141, 71, 21);
  font-size: 1.3vw;
  padding-left: 0.5vw;
  transition: all 1s;
}
.foydalanuvchi-daraja-quti > span > a > .daraja-yuborish:hover {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
.foydalanuvchi-daraja-icon > .front-end {
  padding: 0.5vw;
  font-size: 2vw;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 1vw;
  -webkit-column-gap: 1vw;
          column-gap: 1vw;
  grid-row-gap: 0.9vh;
  row-gap: 0.9vh;
  justify-items: center;
}
.foydalanuvchi-daraja-icon > .back-end {
  padding: 0.5vw;
  font-size: 2vw;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 1vw;
  -webkit-column-gap: 1vw;
          column-gap: 1vw;
  grid-row-gap: 0.9vh;
  row-gap: 0.9vh;
  justify-items: center;
}
.foydalanuvchi-daraja-icon > .front-end > .icon-daraja {
  transition: all 0.5s;
  cursor: pointer;
}
.foydalanuvchi-daraja-icon > .front-end > .icon-daraja:hover {
  color: rgb(141, 71, 21);
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
.foydalanuvchi-daraja-icon > .back-end > .icon-daraja {
  transition: all 0.5s;
  cursor: pointer;
}
.foydalanuvchi-daraja-icon > .back-end > .icon-daraja:hover {
  color: rgb(141, 71, 21);
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.foydalanuvchi-mantiq-icon {
  padding: 0.5vw;
  font-size: 1.5vw;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 0.4vw;
  -webkit-column-gap: 0.4vw;
          column-gap: 0.4vw;
  justify-items: center;
  color: rgba(0, 0, 0, 0.4);
}
.foydalanuvchi-mantiq-icon > .sariq {
  color: rgb(141, 71, 21);
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.5s;
}

.foydalanuvchi-mantiq-icon > .sariq:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.foydalanuvchi-kurs-asosiy-quti {
  display: flex;
  flex-direction: column;
  margin: 1.2vh 0.7vw;
  height: 46vh;
  overflow: auto;
}
.foydalanuvchi-kurs-quti {
  background-color: white;
  z-index: 5;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.foydalanuvchi-kurs-qidiruv {
  margin: 1vh 0.4vw 2vh 0;
}
.foydalanuvchi-kurs-qism {
  display: grid;
  grid-template-columns: 1.1fr 6fr;
  cursor: pointer;
  transition: all 0.5s;
  margin-bottom: 0.8vh;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.foydalanuvchi-kurs-qism:hover {
  -webkit-transform: scale(1);
          transform: scale(1);
  border-bottom: 1px solid rgb(141, 71, 21);
}
.foydalanuvchi-kurs-qism:hover .foydalanuvchi-kurs-nomi {
  color: rgb(141, 71, 21);
}
.foydalanuvchi-kurs-qism > .foydalanuvchi-kurs-rasm > img {
  width: 2.2vw;
}
.foydalanuvchi-kurs > .sarlavha {
  font-weight: 900;
  font-size: 1.1vw;
  display: flex;
  color: rgb(141, 71, 21);
}

.foydalanuvchi-kurs-nomi {
  display: grid;
  grid-template-columns: 3fr 1fr;
  font-weight: 900;
  font-size: 1vw;
  transition: all 0.5s;
  cursor: pointer;
}

.foydalanuvchi-kurs > .sarlavha > span {
  display: inline-block;
  width: 8.5vw;
  text-align: right;
}
.foydalanuvchi-kurs-nomi > span {
  display: inline-block;
  width: 7vw;
  text-align: right;
}
.foydalanuvchi-kurs-icon {
  padding: 0.2vh 0;
  font-size: 1vw;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 0.4vw;
  -webkit-column-gap: 0.4vw;
          column-gap: 0.4vw;
  justify-items: center;
  color: rgba(0, 0, 0, 0.4);
}
.foydalanuvchi-kurs-icon > .sariq {
  color: rgb(141, 71, 21);
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 0.5s;
}

.foydalanuvchi-bank-quti {
  margin-top: 2.3vh;
  padding: 0 0.7vw;
  height: 9.89vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1vw;
  -webkit-column-gap: 1vw;
          column-gap: 1vw;
}

.foydalanuvchi-bank-quti > div {
  background-color: rgba(165, 98, 51, 0.2);
  cursor: pointer;
  border-radius: 0.5vw;
  transition: all 0.2s;
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-items: center;
  font-size: 0.8vw;
  font-weight: 900;
  color: rgb(141, 71, 21);
}

.foydalanuvchi-bank-quti > div:hover {
  border: 0.1vw solid rgb(141, 71, 21);
  box-shadow: 0.25vw 0.1vw 0.4vw rgb(141, 71, 21);
}

.foydalanuvchi-bank-quti > div > .icon {
  font-size: 1.5vw;
}
.foydalanuvchi-bank-quti > div > span > .bosh {
  color: black;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ccc;
  font-family: "Roboto Mono";
  overflow: hidden;
}
.quti {
  display: grid;
  height: 100vh;
  grid-template-columns: 6vw 1fr 20vw;
  grid-template-rows: 1fr;
}

/* .masala-quti {
  border: 1px solid red;
} */
.masala-ijrochi-quti {
  padding: 0 0.7vw;
  width: 72.5vw;
  height: 65.5vh;
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-template-rows: 3fr 2fr;
}
.masala-korinish {
  margin: 0 0.3vw;
  /* border: 1px solid red; */
}
.CodeMirror {
  font-size: 1vw;
  font-family: "Roboto Mono", monospace;
}
.masala-savol-quti > .masala-savol,
.masala-korinish > .natija {
  overflow: auto;
  /* padding: 0 0.7vw; */
  background-color: white;
  border-radius: 0.5vw;
  box-shadow: 0.25vw 0px 1vw rgba(20, 20, 20, 0.397);
}
.tarxirlovchi-quti {
  height: 58.5vh;
  overflow: hidden;
  background-color: #263238;
  border-radius: 0.5vw;
}

.tarxirlovchi-sarlavha {
  color: white;
  font-size: 1.2vw;
  padding: 0.3vh 0;
  display: grid;
  grid-template-columns: 1fr 4fr 6fr;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5;
}
.tarxirlovchi-sarlavha > img {
  padding: 0.3vh 1vw;
  width: 2vw;
}
.tarxirlovchi-sarlavha > button {
  width: 15vw;
  justify-self: end;
  margin: 0.3vh 0.3vw;
  width: 50%;
  font-family: "Roboto Mono", monospace;
  padding: 0.2vw 0;
  border: none;
  border-radius: 0.5vw;
  font-size: 1.1vw;
  background-color: rgba(141, 71, 21, 0.7);
  color: white;
  cursor: pointer;
  transition: all 0.7s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tarxirlovchi-sarlavha > button:hover {
  box-shadow: 0.25vw 0.25vw 1vw rgb(141, 71, 21);
  background-color: rgba(141, 71, 21);
  border-radius: 2vw;
}

.masala-savol-quti > .masala-savol {
  margin: 0 0.7vw;
  padding: 0.7vh 0.7vw;
  height: 12.5vh;
  font-size: 1.2vw;
}
.masala-savol-tartib {
  display: grid;
  grid-template-columns: repeat(40, 1fr);
  justify-items: center;
  align-items: center;
  /* margin: 0 0.7vw; */
  padding: 0.7vh 0.7vw;
  height: 8vh;
}

.masala-savol-tartib > span {
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0.4vh 0;
  border-radius: 0.4vw;
  font-size: 1.1vw;
  width: 1.6vw;
  text-align: center;
  cursor: pointer;
  font-weight: 800;
  box-shadow: 3px 4px 12px white;
  transition: all 0.8s;
}
.masala-savol-tartib > span:hover {
  background-color: rgb(141, 71, 21);
  color: white;
  box-shadow: 3px 4px 12px rgb(141, 71, 21);
}

.masala-savol-tartib > .faol {
  background-color: rgb(141, 71, 21);
  color: white;
  box-shadow: 3px 4px 12px rgb(141, 71, 21);
}

.kurs-quti {
  margin: 0 0.7vw;
}
.kurs-dars-quti {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5vw;
  -webkit-column-gap: 0.5vw;
          column-gap: 0.5vw;
  /* justify-items: center;
  align-items: center; */
}
.kurs-dars-quti {
  height: 46vh;
  overflow: hidden;
}

.topilmadi-quti {
  display: grid;
  grid-template-rows: 3fr 1fr 2fr;
  justify-items: center;
  align-items: center;
  color: rgb(141, 71, 21);
}
.topilmadi-404 {
  font-size: 15vw;
  font-weight: 900;
  align-self: end;
  text-shadow: 10px 15px 20px rgba(141, 71, 21, 0.7);
}
.topilmadi-sahifa {
  font-size: 2vw;
  align-self: start;
}
.topilmadi-tugma-havola {
  text-decoration: none !important;
}
.topilmadi-tugma {
  width: 15vw;
  justify-self: end;
  margin: 0.3vh 0.3vw;
  font-family: "Roboto Mono", monospace;
  padding: 0.5vw 0;
  border: none;
  border-radius: 0.5vw;
  font-size: 1.1vw;
  background-color: rgba(141, 71, 21);
  color: white;
  cursor: pointer;
  transition: all 0.7s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topilmadi-tugma:hover {
  box-shadow: 0.25vw 0.25vw 1vw rgb(141, 71, 21);
  background-color: rgba(141, 71, 21);
  border-radius: 2vw;
}



video {
  width: 100%;
}

