.kard-quti {
  margin: 0.4vw;
  display: grid !important;
  padding: 0.7vh;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 2.5vw;
  row-gap: 0.9vh;
  height: 22.2vh;
}
.kard-icon {
  background-color: white;
  border-radius: 1vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  box-shadow: 0.2vw 0.3vw 0.5vw white;
  transition: all 0.8s;
  cursor: pointer;
  padding-bottom: 5px;
  overflow: hidden;
}
.kard-icon::before {
  content: "Tez kunda";
  font-size: 0.47vw;
  background-color: rgb(141, 71, 21);
  text-align: center;
  transform: rotate(45deg) translate(2.8vw, -6vh);
  color: white;
  padding: 0.2vh 0.3vw;
  width: 5vw;
  border-radius: 0.3vw;
  box-shadow: 0.2vw 0.05vw 0.5vw rgb(141, 71, 21);
}
.kard-icon > img {
  width: 7vw;
  height: 16vh;
  margin-bottom: 0.3vh;
}
.kard-icon > span {
  color: rgb(141, 71, 21);
  font-weight: 900;
  font-size: 0.8vw;
}

.kard-icon:hover {
  /* background-color: rgb(141, 71, 21); */
  box-shadow: 0.2vw 0.3vw 1vw rgb(141, 71, 21);
  transform: scale(1.05);
}
.kardAktiv {
  box-shadow: 0.2vw 0.3vw 1vw rgb(141, 71, 21);
  transform: scale(1.05);
}

.kard-icon:hover span {
  color: black;
}
