.menyu-quti {
  background-color: white;
  box-shadow: 4px 0px 15px rgba(20, 20, 20, 0.397);
  padding: 3px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(9, 1fr);
  height: 100vh;
}
.menyu-qiymat {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  transition: all 0.4s;
  text-align: center;
  margin: 0.1vw 0.1vh;
  text-decoration: none !important;
}
.icon-menyu {
  display: block;
  margin: 0 auto;
  color: rgb(141, 71, 21);
  font-size: 2.5vw;
  transition: all 0.4s;
}
.menyu-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: white; */
  text-decoration: none !important;
}
.menyu-logo img {
  cursor: pointer;
  width: 3vw;
  transition: all 0.5s;
  height: 6vh;
  transition: all 0.8s;
}
.menyu-logo > span {
  color: rgb(141, 71, 21);
  font-size: 1vw;
  text-transform: uppercase;
  font-weight: 900;
  transition: all 0.8;
}
.menyu-logo:hover img {
  transform: scale(1.15);
}
.menyu-logo:hover span {
  transform: skew(5deg);
}

.menyu-qiymat > span {
  color: rgb(141, 71, 21);
  font-size: 1vw;
  font-weight: 700;
}

.menyu-qiymat > img {
  padding: 10px 0;
  margin: 0 auto;
  width: 40%;
}

.menyu-qiymat:hover {
  border-radius: 1vw;
  background-color: rgb(141, 71, 21);
}
.menyu-qiymat:hover span {
  color: white;
}
.menyu-qiymat:hover .icon-menyu {
  color: white;
}

.menyu-quti > .active {
  border-radius: 1vw;
  background-color: rgb(141, 71, 21);
}
.active > .icon-menyu,
.active > span {
  color: white !important;
}
