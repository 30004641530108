.kurs-quti {
  margin: 0 0.7vw;
}
.kurs-dars-quti {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5vw;
  /* justify-items: center;
  align-items: center; */
}
.kurs-dars-quti {
  height: 46vh;
  overflow: hidden;
}
