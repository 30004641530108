.foydalanuvchi-daraja-quti > span {
  font-weight: 900;
  font-size: 1.1vw;
  color: rgb(141, 71, 21);
  padding: 0.5vh 0.5vw;
}
.foydalanuvchi-daraja-quti > span > a > .daraja-yuborish {
  color: rgb(141, 71, 21);
  font-size: 1.3vw;
  padding-left: 0.5vw;
  transition: all 1s;
}
.foydalanuvchi-daraja-quti > span > a > .daraja-yuborish:hover {
  transform: rotate(360deg);
}
.foydalanuvchi-daraja-icon > .front-end {
  padding: 0.5vw;
  font-size: 2vw;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1vw;
  row-gap: 0.9vh;
  justify-items: center;
}
.foydalanuvchi-daraja-icon > .back-end {
  padding: 0.5vw;
  font-size: 2vw;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1vw;
  row-gap: 0.9vh;
  justify-items: center;
}
.foydalanuvchi-daraja-icon > .front-end > .icon-daraja {
  transition: all 0.5s;
  cursor: pointer;
}
.foydalanuvchi-daraja-icon > .front-end > .icon-daraja:hover {
  color: rgb(141, 71, 21);
  transform: scale(1.5);
}
.foydalanuvchi-daraja-icon > .back-end > .icon-daraja {
  transition: all 0.5s;
  cursor: pointer;
}
.foydalanuvchi-daraja-icon > .back-end > .icon-daraja:hover {
  color: rgb(141, 71, 21);
  transform: scale(1.5);
}
