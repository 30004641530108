.qiymat-sarlavha-quti {
  margin: 0 0.7vw;
  display: grid;
  grid-template-columns: 40vw 1fr;
  align-items: center;
  height: 7.5vh;
}
.qiymat-sarlavha-mavzu > p {
  line-height: 0px;
  margin: 0;
  font-size: 1.5vw;
  font-family: "Roboto Mono" monospace;
  color: rgb(141, 71, 21);
}

.qiymat-sarlavha-soni > p {
  line-height: 0px;
  margin: 0;
  font-size: 1.5vw;
  font-family: "Roboto Mono" monospace;
  color: rgb(141, 71, 21);
  text-align: right;
}
