.qiymat-quti-izoh {
  display: grid;
  grid-template-columns: 25% 1fr;
  justify-content: center;
  align-items: center;
}
.qiymat-quti-izoh {
  position: sticky;
  background-color: white;
  top: 0;
  padding-bottom: 0.4vh;
}
.qiymat-mavzu-scroll {
  background-color: white;
  padding: 0.4vw;
  border-radius: 0.4vw;
  box-shadow: 0.25vw 0.25vw 0.7vw rgb(102, 101, 101);
  font-family: "Roboto Mono" monospace;
}
.qiymat-quti-nomi h3 {
  margin: 0;
  padding: 0.25vh 0;
  font-size: 1.7vw;
}
.qiymat-quti-nomi p {
  padding: 0;
  font-size: 1vw;
  margin: 0.25vw 0;
}
.qiymat-quti-nomi span {
  font-weight: bold;
  display: block;
  margin-right: 1vw;
  font-size: 1.2vw;
  text-align: right;
}
.qiymat-quti-logo img {
  width: 7vw;
  height: 16vh;
}

.qiymat-mavzu-quti {
  background-color: white;
  overflow: auto;
  height: 52vh;
  position: relative;
}

.qiymat-quti-scroll {
  padding: 0.4vw;
  background-color: white;
  border-radius: 0.4vw;
  box-shadow: 0.25vw 0.25vw 0.7vw rgb(102, 101, 101);
  font-family: "Roboto Mono" monospace;
}
.qiymat-quti-mavzu ol {
  padding: 0 1vw 0 3.5vw;
}
.qiymat-quti-mavzu ol li {
  font-family: "Roboto Mono";
  transition: all 0.3s;
  font-size: 0.9vw;
  font-weight: 600;
  cursor: pointer;
}
.qiymat-quti-mavzu ol li:hover {
  text-decoration: underline;
}
::-webkit-scrollbar {
  width: 0.7vw;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to top, rgb(141, 71, 21), #ccc);
  border-radius: 0.7vw;
}
