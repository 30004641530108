.foydalanuvchi-bank-quti {
  margin-top: 2.3vh;
  padding: 0 0.7vw;
  height: 9.89vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1vw;
}

.foydalanuvchi-bank-quti > div {
  background-color: rgba(165, 98, 51, 0.2);
  cursor: pointer;
  border-radius: 0.5vw;
  transition: all 0.2s;
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-items: center;
  font-size: 0.8vw;
  font-weight: 900;
  color: rgb(141, 71, 21);
}

.foydalanuvchi-bank-quti > div:hover {
  border: 0.1vw solid rgb(141, 71, 21);
  box-shadow: 0.25vw 0.1vw 0.4vw rgb(141, 71, 21);
}

.foydalanuvchi-bank-quti > div > .icon {
  font-size: 1.5vw;
}
.foydalanuvchi-bank-quti > div > span > .bosh {
  color: black;
}
