@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&family=Roboto+Mono:ital,wght@0,100;0,300;0,400;0,600;0,700;1,200;1,400;1,600;1,700&display=swap");
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ccc;
  font-family: "Roboto Mono";
  overflow: hidden;
}
.quti {
  display: grid;
  height: 100vh;
  grid-template-columns: 6vw 1fr 20vw;
  grid-template-rows: 1fr;
}
