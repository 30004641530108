.foydalanuvchi-kurs-asosiy-quti {
  display: flex;
  flex-direction: column;
  margin: 1.2vh 0.7vw;
  height: 46vh;
  overflow: auto;
}
.foydalanuvchi-kurs-quti {
  background-color: white;
  z-index: 5;
  position: sticky;
  top: 0;
}
.foydalanuvchi-kurs-qidiruv {
  margin: 1vh 0.4vw 2vh 0;
}
.foydalanuvchi-kurs-qism {
  display: grid;
  grid-template-columns: 1.1fr 6fr;
  cursor: pointer;
  transition: all 0.5s;
  margin-bottom: 0.8vh;
  transform: scale(0.95);
}
.foydalanuvchi-kurs-qism:hover {
  transform: scale(1);
  border-bottom: 1px solid rgb(141, 71, 21);
}
.foydalanuvchi-kurs-qism:hover .foydalanuvchi-kurs-nomi {
  color: rgb(141, 71, 21);
}
.foydalanuvchi-kurs-qism > .foydalanuvchi-kurs-rasm > img {
  width: 2.2vw;
}
.foydalanuvchi-kurs > .sarlavha {
  font-weight: 900;
  font-size: 1.1vw;
  display: flex;
  color: rgb(141, 71, 21);
}

.foydalanuvchi-kurs-nomi {
  display: grid;
  grid-template-columns: 3fr 1fr;
  font-weight: 900;
  font-size: 1vw;
  transition: all 0.5s;
  cursor: pointer;
}

.foydalanuvchi-kurs > .sarlavha > span {
  display: inline-block;
  width: 8.5vw;
  text-align: right;
}
.foydalanuvchi-kurs-nomi > span {
  display: inline-block;
  width: 7vw;
  text-align: right;
}
.foydalanuvchi-kurs-icon {
  padding: 0.2vh 0;
  font-size: 1vw;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  column-gap: 0.4vw;
  justify-items: center;
  color: rgba(0, 0, 0, 0.4);
}
.foydalanuvchi-kurs-icon > .sariq {
  color: rgb(141, 71, 21);
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.5s;
}
