/* .masala-quti {
  border: 1px solid red;
} */
.masala-ijrochi-quti {
  padding: 0 0.7vw;
  width: 72.5vw;
  height: 65.5vh;
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-template-rows: 3fr 2fr;
}
.masala-korinish {
  margin: 0 0.3vw;
  /* border: 1px solid red; */
}
.CodeMirror {
  font-size: 1vw;
  font-family: "Roboto Mono", monospace;
}
.masala-savol-quti > .masala-savol,
.masala-korinish > .natija {
  overflow: auto;
  /* padding: 0 0.7vw; */
  background-color: white;
  border-radius: 0.5vw;
  box-shadow: 0.25vw 0px 1vw rgba(20, 20, 20, 0.397);
}
.tarxirlovchi-quti {
  height: 58.5vh;
  overflow: hidden;
  background-color: #263238;
  border-radius: 0.5vw;
}

.tarxirlovchi-sarlavha {
  color: white;
  font-size: 1.2vw;
  padding: 0.3vh 0;
  display: grid;
  grid-template-columns: 1fr 4fr 6fr;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 5;
}
.tarxirlovchi-sarlavha > img {
  padding: 0.3vh 1vw;
  width: 2vw;
}
.tarxirlovchi-sarlavha > button {
  width: 15vw;
  justify-self: end;
  margin: 0.3vh 0.3vw;
  width: 50%;
  font-family: "Roboto Mono", monospace;
  padding: 0.2vw 0;
  border: none;
  border-radius: 0.5vw;
  font-size: 1.1vw;
  background-color: rgba(141, 71, 21, 0.7);
  color: white;
  cursor: pointer;
  transition: all 0.7s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tarxirlovchi-sarlavha > button:hover {
  box-shadow: 0.25vw 0.25vw 1vw rgb(141, 71, 21);
  background-color: rgba(141, 71, 21);
  border-radius: 2vw;
}
