.qidiruv-quti {
  margin: 0 0.7vw;
  display: grid;
  grid-template-columns: 40vw 1fr;
  align-items: center;
  height: 9.5vh;
}
.qidiruv-sarlavha > p {
  font-size: 1.5vw;
  font-family: "Roboto Mono", monospace;
  color: rgb(141, 71, 21);
}
#qidiruv-form > input {
  border: none;
  outline: none;
  font-size: 1.1vw;
  font-family: "Roboto Mono", monospace;
}
#qidiruv-form {
  border-radius: 0.35vw;
  box-shadow: 0.35vw 0.35vw 1vw #ccc;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 10%;
  padding: 0.35vw;
}
#qidiruv-form > button {
  border: none;
  background-color: white;
  cursor: pointer;
  font-size: 1vw;
}
