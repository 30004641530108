.masala-savol-quti > .masala-savol {
  margin: 0 0.7vw;
  padding: 0.7vh 0.7vw;
  height: 12.5vh;
  font-size: 1.2vw;
}
.masala-savol-tartib {
  display: grid;
  grid-template-columns: repeat(40, 1fr);
  justify-items: center;
  align-items: center;
  /* margin: 0 0.7vw; */
  padding: 0.7vh 0.7vw;
  height: 8vh;
}

.masala-savol-tartib > span {
  background-color: rgba(255, 255, 255, 0.9);
  margin: 0.4vh 0;
  border-radius: 0.4vw;
  font-size: 1.1vw;
  width: 1.6vw;
  text-align: center;
  cursor: pointer;
  font-weight: 800;
  box-shadow: 3px 4px 12px white;
  transition: all 0.8s;
}
.masala-savol-tartib > span:hover {
  background-color: rgb(141, 71, 21);
  color: white;
  box-shadow: 3px 4px 12px rgb(141, 71, 21);
}

.masala-savol-tartib > .faol {
  background-color: rgb(141, 71, 21);
  color: white;
  box-shadow: 3px 4px 12px rgb(141, 71, 21);
}
