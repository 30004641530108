.topilmadi-quti {
  display: grid;
  grid-template-rows: 3fr 1fr 2fr;
  justify-items: center;
  align-items: center;
  color: rgb(141, 71, 21);
}
.topilmadi-404 {
  font-size: 15vw;
  font-weight: 900;
  align-self: end;
  text-shadow: 10px 15px 20px rgba(141, 71, 21, 0.7);
}
.topilmadi-sahifa {
  font-size: 2vw;
  align-self: start;
}
.topilmadi-tugma-havola {
  text-decoration: none !important;
}
.topilmadi-tugma {
  width: 15vw;
  justify-self: end;
  margin: 0.3vh 0.3vw;
  font-family: "Roboto Mono", monospace;
  padding: 0.5vw 0;
  border: none;
  border-radius: 0.5vw;
  font-size: 1.1vw;
  background-color: rgba(141, 71, 21);
  color: white;
  cursor: pointer;
  transition: all 0.7s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topilmadi-tugma:hover {
  box-shadow: 0.25vw 0.25vw 1vw rgb(141, 71, 21);
  background-color: rgba(141, 71, 21);
  border-radius: 2vw;
}
