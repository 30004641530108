.foydalanuvchi-malumot {
  padding-top: 0.35vh;
  display: grid;
  grid-template-columns: 25% 1fr 12%;
  align-items: center;
}
.foydalanuvchi-ism {
  display: flex;
  flex-direction: column;
  padding: 0.2vh 0.2vw;
  height: 100%;
  overflow: hidden;
}
.foydalanuvchi-rasm > img {
  width: 100%;
  border-radius: 2.1vw;
}
.foydalanuvchi-qongiroq {
  display: flex;
  justify-content: flex-start;
  margin-right: 1vw;
}
.foydalanuvchi-qongiroq > .icon-qongiroq {
  font-size: 2vw;
  cursor: pointer;
  /* text-align: center; */
  transition: all 0.3s;
}
.foydalanuvchi-qongiroq > .icon-qongiroq:hover {
  color: rgb(141, 71, 21);
  animation: xabarchi 0.25s 3 ease-in alternate;
}
@keyframes xabarchi {
  0% {
    transform: rotate(-20deg);
  }

  100% {
    transform: rotate(20deg);
  }
}
.foydalanuvchi-daraja {
  font-weight: 900;
  font-size: 1.1vw;
  color: rgb(141, 71, 21);
}
.foydalanuvchi-nomi {
  font-size: 1vw;
}
